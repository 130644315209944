import http from "./index";

export const $getCourseList = params => {
	const data = http.post("gkk/Oc_class/KcList", params);
	return data;
};

// 课时详情
export const $getCourseInfo = params => {
	const data = http.post("gkk/Oc_class/info", params, { needToken: true });
	return data;
};

export const $getCommentList = params => {
	const data = http.post("gkk/Oc_class/ksContentList", params, { needToken: true });
	return data;
};

// 课时内容点赞更改状态
export const $changeCourseSupportStatus = params => {
	const data = http.post("gkk/Oc_class/kcudztype", params, { needToken: true });
	return data;
};

// 课时用户更改收藏状态
export const $changeCollectStatus = params => {
	const data = http.post("gkk/Oc_class/upusersc", params, { needToken: true });
	return data;
};

// 用户删除课时评论
export const $deleteComment = params => {
	const data = http.post("gkk/Oc_class/DelContent", params, { needToken: true });
	return data;
};

// 回复评论
export const $replyUser = params => {
	const data = http.post("gkk/Oc_class/kscontentadd", params, { needToken: true });
	return data;
};

// 课时内容点赞更改状态
export const $changeSupportStatus = params => {
	const data = http.post("gkk/Oc_class/kccdztype", params, { needToken: true });
	return data;
};

// 课时评论内容回复
export const $getCommentReplyList = params => {
	const data = http.post("gkk/Oc_class/kcchfpage", params, { needToken: true });
	return data;
};

// 课时评论、维度评论
export const $submitComment = params => {
	const data = http.post("gkk/Oc_class/kscwdadd", params, { needToken: true, ContentType: "multipart/form-data" });
	return data;
};

export const $getCommentStatus = params => {
	const data = http.post("gkk/Oc_class/userwdpltype", params, { needToken: true });
	return data;
};