<!-- 学习课程 -->
<template>
	<div class="study-page">
		<div class="nav-content">
			<div class="nav-title flex-ac">
				<div class="back flex-jc-ac" @click="back">&lt; | 返回</div>
				<div class="chapte-title">{{ title }}</div>
			</div>
		</div>

		<div class="course-content-wrap">
			<div class="course-content">
				<div class="title-contianer flex-jb-ac">
					<div class="left">
						<div class="title">{{ courseInfo.ksname }}</div>
						<div class="info flex-ac">
							<div class="view flex-ac">
								<img src="../assets/image/icon-eye.png" alt="" class="icon" />
								<span>{{ courseInfo.ksllnum }}</span>
							</div>
							<div class="view flex-ac praise" @click="changeCourseSupportStatus">
								<img v-show="courseInfo.userdztype == 2" src="../assets/image/icon-praise3.png" alt="" class="icon" />
								<img v-show="courseInfo.userdztype == 1" src="../assets/image/icon-praise2.png" alt="" class="icon" />
								<span>{{ courseInfo.userdznum }}</span>
							</div>
							<div class="view flex-ac praise" @click="changeCollectStatus">
								<img v-show="courseInfo.sctype == 2" src="../assets/image/icon-collect.png" alt="" class="icon" />
								<img v-show="courseInfo.sctype == 1" src="../assets/image/icon-collect1.png" alt="" class="icon" />
								<span>收藏</span>
							</div>
						</div>
					</div>
					<!-- <div class="right">
						<div class="time">学习时长: {{ courseInfo.kstime }}分钟</div>
						<div class="flex-ac">
							<div class="step-wrap">
								<div class="step" :style="{ width: courseInfo.timebili + '%' }"></div>
							</div>
							<span>{{ courseInfo.timebili }}%</span>
						</div>
					</div> -->
				</div>
				<div class="video-container" v-if="courseInfo.occvideo">
					<video
						@contextmenu.prevent
						:class="{ hide: courseInfo.timebili != 100 }"
						id="player"
						controls="controls"
						:src="courseInfo.occvideo"
						controlsList="nodownload"
						disablePictureInPicture
					></video>
				</div>
				<!-- <div class="btn-wrap flex-jc">
					<div class="btn" v-if="courseInfo.zgttype == 2" @click="goToSubjectivePage">主观题</div>
					<div class="btn active" v-if="courseInfo.kgttype == 2" @click="goToObjectivePage">客观题</div>
				</div> -->
				<div class="introduction-container" v-if="courseInfo.occinfo">
					<!-- <div class="title">简介</div> -->
					<div class="content" v-html="courseInfo.occinfo"></div>
				</div>
			</div>
		</div>
		<div class="comment-container">
			<div class="nav-content">
				<div class="comment-nav flex-jb-ac">
					<div class="title">评论</div>
					<!-- v-if="courseInfo.occpjswitch == 0 && courseInfo.ksuserctype == 2" -->
					<div class="comment-btn" @click="handleClickCommentBtn">我要评论</div>
				</div>
			</div>
			<div class="comment-wrap">
				<div class="comment-content">
					<!-- 我对课程评论的输入框 -->
					<div class="my-comment" v-show="showComment">
						<!-- <div class="rate-wrap flex-jc">
							<div class="rate flex-ac" v-for="item in kswdlist" :key="item.id">
								<span>{{ item.wdname }}</span>
								<el-rate v-model="item.average" :disabled="courseInfo.ksuser_wdtype == 2"></el-rate>
							</div>
						</div> -->
						<div class="my-textarea-wrap flex-jc">
							<textarea maxlength="200" v-model="courseComment" class="textarea" placeholder="您有什么想说的呢？"></textarea>
							<div class="comment-btn" @click="submitCommit">评论</div>
						</div>
					</div>
					<!-- 课程的维度 -->
					<div class="course-rate-wrap flex-ac">
						<div class="course-rate rate-wrap flex-jb-ac flex-wrap">
							<div class="rate flex-ac" v-for="item in courseInfo.kswdlist" :key="item.id">
								<span>{{ item.wdname }}</span>
								<el-rate disabled v-model="item.average"></el-rate>
							</div>
						</div>
						<div class="btn flex-jc-ac flex-col" @click="showMyCourseRateBox = true">
							<div>我要</div>
							<div>评价</div>
						</div>
					</div>

					<!-- 课程的评论列表 -->
					<div class="comment-list">
						<div class="comment flex" v-for="(item, listIndex) in commentList" :key="item.id">
							<img :src="item.userlogo" alt="" class="avatar" />
							<div class="content">
								<div class="info flex-jb">
									<div class="name">{{ item.cname }}</div>
									<div class="options flex-ac">
										<div class="praise flex-ac" @click="changePraise(item)">
											<img v-show="item.kcc_dz_type == 0" src="../assets/image/icon-praise1.png" alt="" />
											<img v-show="item.kcc_dz_type == 1" src="../assets/image/icon-praise2.png" alt="" />
											<span>{{ item.like }}</span>
										</div>
										<div class="detele" v-if="userInfo.id == item.sid" @click="handleDetele(item.id, listIndex)">删除</div>
									</div>
								</div>
								<pre class="txt">{{ item.content }}</pre>
								<!-- <div class="rate-wrap flex">
									<div class="rate flex-ac" v-for="(i, index) in item.wdlist" :key="index">
										<span>{{ i.wdame }}</span>
										<el-rate disabled v-model="i.wdf"></el-rate>
									</div>
								</div> -->
								<div class="time-wrap flex-jb-ac">
									<div class="time">{{ item.createtime }}</div>
									<div class="reply" @click="showReplyBox(item, item.id)">回复</div>
								</div>
								<div class="reply-list-container flex" v-for="(i, index) in item.hflist" :key="index">
									<img :src="i.userlogo" alt="" class="replyer-avatar" />
									<div class="reply-list">
										<div class="reply-content">
											<span class="name">{{ i.cname }}</span>
											回复
											<span class="name">{{ i.hfname }}</span>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<!-- <span class="reply-txt">&nbsp;&nbsp;&nbsp;&nbsp;{{ i.content }}</span> -->
											<pre class="reply-txt">{{ i.content }}</pre>
										</div>
										<div class="time">{{ i.createtime }}</div>
										<div class="options flex-ac">
											<div class="praise flex-ac" @click="changePraise(i)">
												<img v-show="i.kcc_dz_type == 0" src="../assets/image/icon-praise1.png" alt="" />
												<img v-show="i.kcc_dz_type == 1" src="../assets/image/icon-praise2.png" alt="" />
												<span>{{ i.like }}</span>
											</div>
											<div class="reply-btn" @click="showReplyBox(i, item.id)">回复</div>
										</div>
									</div>
								</div>
								<div class="more-reply-btn" v-if="item.kcc_hfcount != item.hflist.length" @click="showMoreReply(item)">共{{ item.kcc_hfcount }}条回复，点击<span>查看更多</span></div>
								<div class="my-textarea-wrap flex-jc" :id="'textarea' + item.id" v-show="item.showReplyBox">
									<img class="my-avatar" src="https://static.mudu.tv/index/avatar.png" alt="" />
									<textarea maxlength="200" class="textarea" :placeholder="placeholder" v-model.trim="replyUserContent"></textarea>
									<div class="comment-btn flex-jc-ac flex-col" @click="replyUser(item)">
										<div>回复</div>
										<div>评论</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="pagination flex-jc-ac" v-if="courseInfo.kcccountyiji > 10">
					<div class="btn first-pagination" :class="{ disabled: currentPage == 1 }" @click="changeCurrentPage(1)">首页</div>
					<el-pagination
						hide-on-single-page
						next-text="下一页"
						@current-change="getCommentList"
						:current-page.sync="currentPage"
						:page-size="pageSize"
						prev-text="上一页"
						layout="prev, pager, next"
						:total="courseInfo.kcccountyiji || 0"
					>
					</el-pagination>
					<div class="btn end-pagination" :class="{ disabled: currentPage == lastPageCount }" @click="changeCurrentPage(lastPageCount)">尾页</div>
				</div>
			</div>
		</div>
		<div class="page-mask" v-if="showMyCourseRateBox">
			<div class="centerBox my-course-rate-wrap">
				<img class="close" @click="showMyCourseRateBox = false" src="../assets/image/close.png" alt="" />
				<div class="rate flex-ac" v-for="item in kswdlist" :key="item.id">
					<span>{{ item.wdname }}</span>
					<el-rate v-model="item.average" :disabled="courseInfo.ksuser_wdtype == 2"></el-rate>
				</div>
				<div class="btn" @click="submitRate">提交</div>
			</div>
		</div>
		<img src="../assets/image/icon-rocket.png" v-show="showBackTop" @click="toTop" alt="回到顶部" title="回到顶部" class="back-top" />
	</div>
</template>

<script>
import {
	$getCourseInfo,
	$getCommentList,
	$changeCollectStatus,
	$changeCourseSupportStatus,
	$replyUser,
	$changeSupportStatus,
	$getCommentReplyList,
	$deleteComment,
	$submitComment,
	$getCommentStatus,
} from "../services/openClass";
import scrollTo from "../utils/scrollToElement";
export default {
	name: "Study",
	data() {
		return {
			courseId: "",
			lessonId: "", // 课时id
			title: "",
			courseInfo: {}, // 课时信息
			commentList: [], // 评论列表
			kswdlist: [], // 维度列表
			showpraise: true,
			showComment: false,
			courseComment: "",
			rateExciting: 3, // 精彩度评分
			rateSpeciality: 3, // 专业性评分
			pageSize: 10,
			currentPage: 1,
			placeholder: "您有什么想说的呢？",
			wsTimer: null,
			submitComitLoading: false,
			replyUserContent: "",
			showBackTop: false,
			toReplyerUser: {},
			showMyCourseRateBox: false
		};
	},
	components: {},

	computed: {
		lastPageCount() {
			if (!this.courseInfo.kcccountyiji) return 0;
			return Math.ceil(this.courseInfo.kcccountyiji / this.pageSize);
		},
		userInfo() {
			return this.$store.getters.useInfo;
		},
	},

	created() {
		this.courseId = this.$route.query.id;
		this.title = this.$route.query.title;
		this.getCourseInfo();
		this.getCommentList();
	},

	mounted() {
		window.addEventListener("scroll", this.handleScroll, true);
	},

	destroyed() {
		window.removeEventListener("scroll", this.handleScroll, true);
	},

	methods: {
		async getCourseInfo() {
			const { data } = await $getCourseInfo({
				kcid: this.courseId,
			});
			this.courseInfo = JSON.parse(JSON.stringify(data));
			this.kswdlist = [...data.ksuserwdlist];
		},
		watchVideo() {
			var tarr = [];
			var video = document.querySelector("video");
			video.addEventListener(
				"timeupdate",
				function (e) {
					tarr.unshift(video.currentTime);
					tarr.length = 2;
				},
				false
			);
			video.addEventListener(
				"seeking",
				function (e) {
					if (video.currentTime > tarr[1] && this.courseInfo.timebili != 100) {
						video.currentTime = tarr[1];
					}
				},
				false
			);
		},
		async getCommentList() {
			const { data } = await $getCommentList({
				kcid: this.courseId,
				page: this.currentPage,
				limit: this.pageSize,
			});
			this.commentList = data;
		},
		async showMoreReply(item) {
			const pid = item.hflist.length > 0 ? item.hflist[0].pid : 0;
			const page = item.replyPage ? item.replyPage + 1 : 2;
			const { data } = await $getCommentReplyList({
				kcid: this.courseId,
				pid,
				page,
				limit: 5,
			});
			if (data.length > 0) {
				this.$set(item, "replyPage", page);
				item.hflist.push(...data);
			}
		},
		async changeCourseSupportStatus() {
			const { data } = await $changeCourseSupportStatus({
				kcid: this.courseId,
			});
			if (data == 1) {
				this.$set(this.courseInfo, "userdznum", this.courseInfo.userdznum + 1);
			} else {
				this.$set(this.courseInfo, "userdznum", this.courseInfo.userdznum - 1);
			}
			this.$set(this.courseInfo, "userdztype", data);
		},
		// 改变收藏状态
		async changeCollectStatus() {
			const { data } = await $changeCollectStatus({
				kcid: this.courseId,
			});
			if (data.sctype == 1) {
				this.$set(this.courseInfo, "ksuscnum", this.courseInfo.ksuscnum + 1);
			} else {
				this.$set(this.courseInfo, "ksuscnum", this.courseInfo.ksuscnum - 1);
			}
			this.$set(this.courseInfo, "sctype", data.sctype);
		},
		// 显示隐藏回复评论框
		showReplyBox(item, id) {
			console.log(item, id);
			this.commentList.forEach(i => {
				if (i.id == id) {
					this.$set(i, "showReplyBox", true);
				} else {
					this.$set(i, "showReplyBox", false);
				}
			});
			this.placeholder = "回复" + item.cname + ":";
			this.toReplyerUser = item;
			this.$nextTick(() => {
				scrollTo("textarea" + id);
			});
		},
		async submitCommit() {
			if (this.submitComitLoading) {
				this.$message.error("提交中，请勿重复提交");
				return;
			}
			if (this.courseComment.trim() == "") {
				this.$message.error("您还没有输入评论内容");
				return;
			}
			this.submitComitLoading = true;
			let req = new FormData();
			req.append("kcid", this.courseId);
			const sid = this.$store.getters.useInfo.id;
			req.append("sid", sid);
			req.append("content", this.courseComment);

			const { data } = await $submitComment(req);
			this.submitComitLoading = false;
			this.courseComment = "";
			this.getCommentList();
			this.$message.success("评论成功");
			this.showComment = false;
		},

		async submitRate() {
			if(this.courseInfo.ksuser_wdtype == 2) {
				this.$message.error("您已评价过了，不能再次评价");
				return
			}
			if (this.submitComitLoading) {
				this.$message.error("提交中，请勿重复提交");
				return;
			}

			if (this.kswdlist.some(i => i.average == 0)) {
				this.$message.error("您还有没有评价没有打分");
				return;
			}

			this.submitComitLoading = true;
			let req = new FormData();
			req.append("kcid", this.courseId);
			const sid = this.$store.getters.useInfo.id;
			req.append("sid", sid);
			this.kswdlist.forEach(item => {
				req.append(`wdaddlist[${item.id}]`, item.average);
			});
			const { data } = await $submitComment(req);
			this.submitComitLoading = false;
			this.$message.success("评价成功");
			this.$set(this.courseInfo, "ksuser_wdtype", 2);
			this.showMyCourseRateBox = false;
			this.$set(this.courseInfo, "kswdlist", data);
		},

		async handleClickCommentBtn() {
			const { data } = await $getCommentStatus({
				kcid: this.courseId,
			});
			if (data.ksuserlist.length > 0) {
				this.kswdlist = data.ksuserlist;
			} else {
				this.kswdlist = data.kswdlist;
			}
			this.showComment = !this.showComment;
		},
		// 点赞状态更改
		async changePraise(item) {
			const { data } = await $changeSupportStatus({
				kccontid: item.id,
			});
			const kcc_dz_type = data.kccdztype == 1 ? 1 : 0;
			const count = data.kccdztype == 1 ? item.like + 1 : item.like - 1;
			this.$set(item, "kcc_dz_type", kcc_dz_type);
			this.$set(item, "like", count);
		},
		async handleDetele(id, index) {
			const { data } = await $deleteComment({
				kcid: this.courseId,
				kccontid: id,
			});
			if (data == 0) {
				this.$message.success("删除成功");
				this.commentList.splice(index, 1);
				const count = this.courseInfo.kcccountyiji - 1;
				this.$set(this.courseInfo, "kcccountyiji", count);

				if (this.commentList.length == 0 && this.currentPage > 1) {
					this.currentPage--;
					this.getCommentList();
				}
			}
		},
		async replyUser(item) {
			if (!this.replyUserContent) return;
			const data = await $replyUser({
				cid: item.id,
				content: this.replyUserContent,
				kcid: this.courseId,
				huuserid: this.toReplyerUser.sid,
			});
			this.$message.success("回复成功");
			// this.$set(item, "showReplyBox", false);
			this.getCommentList();
			this.replyUserContent = "";
		},
		changeCurrentPage(currentPage) {
			if (this.currentPage == currentPage) return;
			this.currentPage = currentPage;
			this.getCommentList();
		},
		goToSubjectivePage() {
			this.$router.push({
				path: "/subjectiveWork",
				query: {
					title: this.title,
					kcid: this.courseId,
					id: this.lessonId,
				},
			});
		},
		goToObjectivePage() {
			this.$router.push({
				path: "/objectiveWork",
				query: {
					title: this.title,
					kcid: this.courseId,
					id: this.lessonId,
				},
			});
		},
		back() {
			this.$router.back();
		},
		handleScroll() {
			let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
			scrolltop > 30 ? (this.showBackTop = true) : (this.showBackTop = false);
		},
		toTop() {
			let top = document.documentElement.scrollTop || document.body.scrollTop;
			// 实现滚动效果
			const timeTop = setInterval(() => {
				document.body.scrollTop = document.documentElement.scrollTop = top -= 100;
				if (top <= 0) {
					clearInterval(timeTop);
				}
			}, 10);
		},
	},
};
</script>

<style scoped lang="less">
.study-page {
	min-height: calc(100vh - 235px);
}
.nav-content {
	height: 52px;

	background: #f3f5f6;
	.nav-title {
		width: 1200px;
		height: 52px;
		margin: 0 auto;
		font-size: 16px;
		color: #1c1f21;
		.back {
			width: 120px;
			height: 37px;
			border-radius: 20px;
			color: #fff;
			margin-right: 20px;
			background-color: rgba(153, 153, 153, 1);
			// background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
			cursor: pointer;
		}
	}
}
.course-content-wrap {
	background: #4d555d;
	color: #f3f5f6;
	.course-content {
		width: 1200px;
		margin: 0 auto;
		padding-bottom: 58px;
		.title-contianer {
			height: 100px;
			.left {
				// padding-top: 32px;
				.title {
					font-size: 36px;
					color: #f3f5f6;
				}
				.info {
					margin-top: 15px;
					font-size: 14px;
					.view {
						line-height: 16px;
						margin-right: 40px;
						img {
							height: 16px;
							margin-right: 16px;
						}
					}
					.praise {
						cursor: pointer;
					}
				}
			}
			.right {
				padding-top: 50px;
				line-height: 16px;
				.time {
					margin-bottom: 20px;
					text-align: center;
					padding-right: 20px;
				}
				.step-wrap {
					width: 200px;
					height: 16px;
					background: rgba(255, 255, 255, 0.5);
					border-radius: 8px;
					overflow: hidden;
					margin: 0 18px;
					.step {
						height: 16px;
						background: linear-gradient(90deg, #81e887 0%, #46d5f4 100%);
						opacity: 0.96;
					}
				}
			}
		}
		.video-container {
			width: 1200px;
			height: 628px;
			#player {
				width: 1200px;
				height: 628px;
			}
		}
		.btn-wrap {
			width: 1200px;
			height: 135px;
			padding-top: 43px;
			.btn {
				width: 178px;
				height: 54px;
				line-height: 54px;
				text-align: center;
				background-color: rgba(255, 255, 255, 0.3);
				border-radius: 27px;
				margin-right: 242px;
				background-color: rgba(255, 255, 255, 0.5);
				cursor: pointer;
				user-select: none;
				&.active {
					background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
				}
				&:last-child {
					margin-right: 0;
				}
			}
		}
		.introduction-container {
			width: 1200px;
			background-color: rgba(255, 255, 255, 0.1);
			padding: 40px 38px 60px 26px;
			font-size: 16px;
			line-height: 26px;
			color: #ffffff;
			margin-top: 50px;
			.title {
				font-size: 22px;
				margin-bottom: 20px;
			}
			.content /deep/ img {
				max-width: 1136px;
			}
		}
	}
}
.comment-container {
	.nav-content {
		width: 100%;
		height: 100px;
		background: #f3f5f6;
		.comment-nav {
			height: 100%;
			width: 1200px;
			margin: 0 auto;
			font-size: 22px;
			color: #1c1f21;
			.comment-btn {
				cursor: pointer;
				user-select: none;
				width: 102px;
				height: 36px;
				background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
				border-radius: 18px;
				line-height: 36px;
				text-align: center;
				font-size: 13px;
				color: #ffffff;
			}
		}
	}
	.comment-wrap {
		padding-top: 28px;
		.comment-content {
			width: 1200px;
			margin: 0 auto;
			border-radius: 8px;
			box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.2);
			margin-bottom: 40px;
			padding: 40px;
			.my-comment {
				border-bottom: 1px solid #eeefef;
				padding-bottom: 40px;
				margin-bottom: 40px;
				.rate-wrap {
					.rate {
						margin-right: 70px;
						font-size: 18px;
						line-height: 24px;
						color: #1c1f21;
						white-space: nowrap;
						&:last-child {
							margin-right: 0;
						}
						.el-rate {
							margin-left: 15px;
							/deep/ .el-rate__icon {
								font-size: 22px;
							}
						}
					}
				}

				.my-textarea-wrap {
					// margin-top: 40px;
					.textarea {
						resize: none;
						padding: 11px 15px;
						width: 858px;
						height: 80px;
						background-color: rgba(243, 245, 246, 0.8);
						border: none;
						outline: none;
					}
					.comment-btn {
						width: 80px;
						height: 80px;
						text-align: center;
						line-height: 80px;
						background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
						border-radius: 4px;
						font-size: 16px;
						color: #ffffff;
						margin-left: 10px;
						cursor: pointer;
						user-select: none;
					}
				}
			}
			.course-rate-wrap {
				width: 940px;
				margin: 0 auto;
				.course-rate {
					font-size: 24px;
					.rate {
						width: 50%;
						margin: 10px 0;
					}
					.el-rate {
						margin-left: 10px;
						height: 30px;
						/deep/ .el-rate__icon {
							font-size: 30px;
						}
					}
				}
				.btn {
					flex: none;
					width: 80px;
					height: 80px;
					text-align: center;
					background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
					border-radius: 4px;
					font-size: 16px;
					color: #ffffff;
					margin-left: 10px;
					cursor: pointer;
					user-select: none;
				}
			}

			.comment-list {
				// width: 1120px;
				margin-top: 45px;
				.comment {
					border-top: 1px solid #eeefef;
					padding: 50px 0;
					.avatar {
						width: 58px;
						height: 58px;
						border-radius: 50%;
						margin-right: 34px;
					}
					.content {
						flex: 1;
						.info {
							font-size: 15px;
							color: #7c899a;
							.name {
								font-size: 20px;
								font-weight: bold;
								color: #1c1f21;
							}
							.options {
								.praise {
									cursor: pointer;
									img {
										width: 16px;
										height: 16px;
										margin-right: 16px;
									}
								}
								.detele {
									margin-left: 43px;
									cursor: pointer;
								}
							}
						}
						.txt {
							font-size: 20px;

							color: #1c1f21;
							margin: 30px 0;
							line-height: 30px;
						}
						.rate-wrap {
							color: #8c9094;
							font-size: 14px;
							margin-bottom: 35px;
							flex-wrap: wrap;
							.rate {
								margin-right: 40px;
								.el-rate {
									margin-left: 10px;
								}
							}
						}
						.time-wrap {
							font-size: 13px;
							color: #7c899a;
							.reply {
								font-size: 15px;
								cursor: pointer;
								user-select: none;
								text-decoration: underline;
							}
						}
						.reply-list-container {
							padding-top: 15px;
							margin-top: 15px;
							border-top: 1px solid #eeefef;
							.replyer-avatar {
								width: 58px;
								height: 58px;
								border-radius: 50%;
								margin-right: 33px;
								flex: none;
							}
							.reply-list {
								.reply-content {
									width: 930px;
									line-height: 28px;
									color: #7c899a;
									font-size: 16px;
									word-break: break-all;
									.name {
										color: #3382ff;
									}
									.reply-txt {
										color: #1c1f21;
									}
									pre{
										line-height: 20px;
									}
								}
								.time {
									color: #7c899a;
									margin: 10px 0;
								}
								.options {
									margin-top: 20px;
									color: #7c899a;
									.praise {
										margin-right: 45px;
										cursor: pointer;
										img {
											width: 14px;
											margin-right: 12px;
										}
									}
									.reply-btn {
										cursor: pointer;
										text-decoration: underline;
									}
								}
							}
						}
						.reply-comment {
							margin-top: 30px;
							padding-top: 18px;
							border-top: 1px solid #eeefef;
							.replyer-avatar {
								width: 58px;
								height: 58px;
								border-radius: 50%;
								margin-right: 25px;
							}
							.reply-content-wrap {
								.reply-content {
									font-size: 15px;
									color: #1c1f21;
									line-height: 30px;
									span {
										color: #3382ff;
									}
								}
								.praise-wrap {
									margin-top: 25px;
									cursor: pointer;
									color: #7c899a;
									.praise {
										margin-right: 45px;
										img {
											width: 14px;

											margin-right: 12px;
										}
									}
								}
							}
						}
						.more-reply-btn {
							margin-top: 20px;
							color: #7c899a;
							span {
								color: #3382ff;
								cursor: pointer;
							}
						}
						.my-textarea-wrap {
							margin-top: 35px;
							.my-avatar {
								width: 58px;
								height: 58px;
								border-radius: 50%;
								margin-right: 25px;
							}
							.textarea {
								resize: none;
								padding: 11px 15px;
								width: 858px;
								height: 80px;
								background-color: rgba(243, 245, 246, 0.8);
								border: none;
								outline: none;
							}
							.comment-btn {
								width: 80px;
								height: 80px;
								text-align: center;
								background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
								border-radius: 4px;
								font-size: 16px;
								color: #ffffff;
								margin-left: 10px;
								cursor: pointer;
								user-select: none;
							}
						}
					}
				}
			}
		}
	}
	.pagination {
		margin: 60px 0;
		font-size: 15px;
		color: #4d555d;
		.btn {
			cursor: pointer;
			&.disabled {
				color: #c0c4cc;
				cursor: not-allowed;
			}
		}
		.el-pagination {
			margin: 0 10px;
			display: flex;
			align-items: center;
			/deep/ span,
			/deep/ li {
				font-size: 15px;
				font-weight: normal;
			}
			/deep/ .el-pager li {
				line-height: 36px;
				&.active {
					color: #fff;
					width: 36px;
					height: 36px;
					background: #4d555d;
					border-radius: 50%;
				}
			}
		}
	}
}
video.hide::-webkit-media-controls-timeline {
	display: none;
}
.back-top {
	position: fixed;
	right: 100px;
	bottom: 100px;
	width: 100px;
	cursor: pointer;
}
pre {
	display: inline-block;
	font-family: sans-serif;
	white-space: pre-wrap;
	word-wrap:break-word;
	vertical-align:text-top;
}
.my-course-rate-wrap {
	background-color: #fff;
	padding: 30px 50px;
	border-radius: 10px;
	.close {
		position: absolute;
		right: -5px;
		top: -5px;
		width: 30px;
		cursor: pointer;
	}
	.rate {
		font-size: 20px;
		line-height: 24px;
		color: #1c1f21;
		white-space: nowrap;
		margin: 10px;
		.el-rate {
			margin-left: 15px;
			/deep/ .el-rate__icon {
				font-size: 24px;
			}
		}
	}
	.btn {
		width: 140px;
		height: 40px;
		margin: 30px auto 0;
		line-height: 40px;
		text-align: center;
		background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
		border-radius: 20px;
		font-size: 16px;
		color: #ffffff;
		cursor: pointer;
		user-select: none;
	}
}
</style>
